@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap");

body{
  background-color: white;
}
/* .zoom-custom{
  zoom: 90%;
} */

h1,h2,h3,h4,h5,span,p{
  color: black;
}
.shadow-mdl{
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.border_product_card{
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

html {
  scroll-behavior: smooth;
  --daisyui-base: #fff; 
  --daisyui-primary: #ffffff; 
  /* Add more overrides as needed */
}
.font-all {
  font-family: "Montserrat", sans-serif;
}
.bg-nav {
  background: rgba(203, 34, 41, 0.15);
  border-width: 1px 0px;
  border-style: solid;
  border-color: #cb2229;
}

/* Track */
::-webkit-scrollbar {
  width: 6px; /* Adjust the width as needed */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #000000; /* Set the color of the scrollbar */
  border-radius: 6px; /* Ma6e it rounded */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #000000; /* Set a different color on hover if desired */
}

/* Track */
::-webkit-scrollbar-track {
  /* background-color: #f3f4f6; Set the color of the scrollbar track */
  border-radius: 4px; /* Make it rounded */
}

/* Handle when dragging */
::-webkit-scrollbar-thumb:active {
  background-color: #9ca3af; /* Set a different color when dragging if desired */
}


.shadow-l {
  filter: drop-shadow(0px 0px 12px rgba(0, 0, 0, 0.15));
}
.bg-layout {
  background: #ffffff;
  border: 1px solid #f8f8f8;
  box-shadow: 0px 0px 8px 3px rgba(203, 34, 41, 0.05);
  border-radius: 10px;
}
.border-a {
  border: 1px solid #738482;
}
.text-color {
  color: #263238;
}
/* z */
.scrollbar::-webkit-scrollbar {
  width: 6px;
}

.scrollbar::-webkit-scrollbar-track {
  background: rgb(0, 0, 0);
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: rgb(211, 0, 0);
  border-radius: 3px;
}
.tilt-in-right-1 {
	-webkit-animation: tilt-in-right-1 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: tilt-in-right-1 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-11-9 3:31:43
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation tilt-in-right-1
 * ----------------------------------------
 */
@-webkit-keyframes tilt-in-right-1 {
  0% {
    -webkit-transform: rotateX(-30deg) translateX(300px) skewX(30deg);
            transform: rotateX(-30deg) translateX(300px) skewX(30deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg) translateX(0) skewX(0deg);
            transform: rotateX(0deg) translateX(0) skewX(0deg);
    opacity: 1;
  }
}
@keyframes tilt-in-right-1 {
  0% {
    -webkit-transform: rotateX(-30deg) translateX(300px) skewX(30deg);
            transform: rotateX(-30deg) translateX(300px) skewX(30deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg) translateX(0) skewX(0deg);
            transform: rotateX(0deg) translateX(0) skewX(0deg);
    opacity: 1;
  }
}
.jello-horizontal {
	-webkit-animation: jello-horizontal 0.9s both;
	        animation: jello-horizontal 0.9s both;
}
/* ----------------------------------------------
 * Generated by Animista on 2024-1-3 16:29:27
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation jello-horizontal
 * ----------------------------------------
 */
 @-webkit-keyframes jello-horizontal {
  /* 0% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
            transform: scale3d(1.25, 0.75, 1);
  } */
  /* 40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
            transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
            transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
            transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
            transform: scale3d(1.05, 0.95, 1);
  } */
  100% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
}
@keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
            transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
            transform: scale3d(0.75, 1.25, 1);
  }
  /* 50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
            transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
            transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
            transform: scale3d(1.05, 0.95, 1);
  } */
  100% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
}

.slide-in-blurred-top {
	-webkit-animation: slide-in-blurred-top 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
	        animation: slide-in-blurred-top 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
}
/*  */
/* ----------------------------------------------
 * Generated by Animista on 2024-1-3 19:31:14
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-blurred-top
 * ----------------------------------------
 */
 @-webkit-keyframes slide-in-blurred-top {
  0% {
    -webkit-transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
            transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    -webkit-filter: blur(40px);
            filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) scaleY(1) scaleX(1);
            transform: translateY(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
}
@keyframes slide-in-blurred-top {
  0% {
  
            transform-origin: 50% 0%;
    -webkit-filter: blur(40px);
            filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) scaleY(1) scaleX(1);
            transform: translateY(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
}
.scrollable-div::-webkit-scrollbar {
  display: none;
}


/* Styles for screens with a width between 1080px and 1919px */
@media screen and (min-width: 1366px) and (max-height: 768px) {
  /* CSS rules for 1080px resolution */
  .zoom-custom{
    zoom: 90%;
  } 
}

@media screen and (min-width: 1280px) and (max-height: 720px) {
  /* CSS rules for 1080px resolution */
  .zoom-custom{
    zoom: 85%;
  } 
}

